/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    item: {},
    products: [
      "Blade"
    ]
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    Set_Selected_Item(state,item){
      state.item=item
    }
  },
  actions: {
    setUser({ commit }, user) {
      commit('SET_USER', user);
    },
    setProducts({ commit }, products) {
      commit('SET_PRODUCTS', products);
    },
    setSelectedItem({ commit }, item) {
      commit('Set_Selected_Item', item);
    },
  },
  getters: {
    getUser: state => state.user,
    getItem: state => state.item,
    getProducts: state => state.products
  }
});
